<script setup lang="ts">
import { useDisplay } from 'vuetify'

const headerBgColor = '#0c1012DD'
const drawerBgColor = 'surface'

const userStore = useUserStore()
const { t, setLocale } = useI18n()
const { lgAndUp } = useDisplay()
const drawer = ref(false)

interface HeaderNavItem {
  text: string
  link: string
  location: 'left' | 'right'
  position: number
  class?: string
}

const navItems = computed<HeaderNavItem[]>(() => {
  return [
    // left nav items
    {
      text: t('SiteLayoutHeader.menu.1'),
      link: `${localeLink('help/user/getting-started')}`,
      location: 'left',
      position: 1,
    },
    {
      text: t('SiteLayoutHeader.menu.2'),
      link: userStore.isAuthenticated
        ? localeLink('private-catalog-search')
        : localeLink('site-catalog-search'),
      location: 'left',
      position: 2,
    },
    // {
    //   text: t('SiteLayoutHeader.menu.3'),
    //   link: localeLink('site-desktop-download'),
    //   location: 'left',
    //   position: 3,
    // },
    {
      text: t('SiteLayoutHeader.menu.4'),
      link: localeLink('/blog'),
      location: 'left',
      position: 4,
    },

    // right nav items

    /*
    {
    text: t('SiteLayoutHeader.menu.4'),
    link: `${localeLink('index')}#support-divine`,
    location: 'right',
    position: 1,
    class: 'clear',
    },
    */
    {
      text: t('SiteLayoutHeader.menu.6'),
      link: localeLink('help'),
      location: 'right',
      position: 2,
      class: 'clear',
    },
  ]
})

const leftNavItems = computed(() =>
  navItems.value.filter((item) => item.location === 'left').sort((a, b) => a.position - b.position),
)
const rightNavItems = computed(() =>
  navItems.value
    .filter((item) => item.location === 'right')
    .sort((a, b) => a.position - b.position),
)
</script>

<template>
  <VAppBar
    name="header-appbar"
    :height="useAppConfig().siteConfig.headerHeight"
    :color="headerBgColor"
    flat
    class="dvn-site-header no-selection"
  >
    <VAppBarNavIcon variant="text" class="d-block d-md-none" @click.stop="drawer = !drawer" />

    <div class="ml-1">
      <LogoHeader class="d-none d-md-block" />
    </div>
    <!-- <VSpacer /> -->

    <LogoHeader width="20px" class="d-md-none" style="width: 20px; max-width: 20px" />

    <!-- left nav -->

    <div class="dvn-site-header-nav left d-none d-md-flex">
      <div v-for="(item, i) in leftNavItems" :key="i">
        <span v-if="i > 0" class="separator">|</span>
        <NuxtLink :to="item.link" class="dvn-site-link" :class="item.class">{{
          item.text
        }}</NuxtLink>
      </div>
    </div>

    <VSpacer />

    <!-- right nav -->
    <div class="dvn-site-header-nav right d-none d-lg-flex">
      <div v-for="(item, i) in rightNavItems" :key="i">
        <span v-if="i > 0" class="separator"> | </span>
        <NuxtLink :to="item.link" class="dvn-site-link" :class="item.class">{{
          item.text
        }}</NuxtLink>
      </div>
    </div>

    <!-- cart -->
    <LayoutsNavbarCart />

    <div v-if="userStore.isAuthenticated" class="dvn-site-header-toolbar">
      <LayoutsNavbarI18n />
      <!-- account -->
      <LayoutsUserProfileMenu />
    </div>

    <!-- v-else removed here as it introduces a bug after build -->
    <div v-if="!userStore.isAuthenticated" class="dvn-site-header-toolbar ml-2">
      <div class="dvn-site-header-account">
        <NuxtLink :to="localeLink('login')" class="dvn-site-link-button rev">{{
          $t('SiteLayoutHeader.Login')
        }}</NuxtLink>
        <NuxtLink
          :to="localeLink('register')"
          class="dvn-site-link-button register d-none d-md-flex"
          >{{ $t('SiteLayoutHeader.Register') }}</NuxtLink
        >
      </div>

      <!-- lang -->
      <div v-if="lgAndUp" class="dvn-site-header-lang">
        <VIcon
          class="dvn-site-header-lang-icon"
          icon="circle-flags:gb"
          size="1.2em"
          @click="setLocale('en')"
        />
        <VIcon
          class="dvn-site-header-lang-icon"
          icon="circle-flags:fr"
          size="1.2em"
          @click="setLocale('fr')"
        />
      </div>
    </div>
  </VAppBar>

  <!-- z-index: 1006 : fix to avoid hydration issue -->
  <VNavigationDrawer
    v-model="drawer"
    location="left"
    temporary
    width="210"
    :color="drawerBgColor"
    style="z-index: 1006"
  >
    <VList :lines="false" nav>
      <VListItem
        v-for="(item, i) in leftNavItems"
        :key="i"
        :value="item.text"
        :to="item.link"
        link
        class="dvn-site-link"
        :class="item.class"
      >
        <VListItemTitle>
          {{ item.text }}
        </VListItemTitle>
      </VListItem>
      <VDivider />
      <VListItem
        v-for="(item, i) in rightNavItems"
        :key="i"
        :value="item.text"
        :to="item.link"
        link
        class="dvn-site-link"
        :class="item.class"
      >
        <VListItemTitle>
          {{ item.text }}
        </VListItemTitle>
      </VListItem>
      <VDivider />
      <VListItem v-if="!userStore.isAuthenticated" style="text-align: center">
        <VIcon
          class="dvn-site-header-lang-icon"
          icon="circle-flags:gb"
          size="1.2em"
          @click="setLocale('en')"
        />
        <VIcon
          class="dvn-site-header-lang-icon"
          icon="circle-flags:fr"
          size="1.2em"
          @click="setLocale('fr')"
        />
      </VListItem>
    </VList>
  </VNavigationDrawer>
</template>

<style lang="css" scoped>
header.dvn-site-header {
  backdrop-filter: blur(8px);
}

div.dvn-site-header-nav,
div.dvn-site-header-toolbar,
div.dvn-site-header-account {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.dvn-site-header-nav .separator {
  margin: 0 0.5em;
}

.dvn-site-header-nav.left {
  margin-left: 1.5em;
}

.dvn-site-header-nav.right {
  margin-right: 1em;
}

div.dvn-site-header-account a.dvn-site-link-button {
  font-size: 0.7em;
  margin-right: 0.4em;
}

div.dvn-site-header-lang {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0.1vw 0 0.2em;
}

svg.dvn-site-header-lang-icon {
  margin-right: 5px;
  cursor: pointer;
}

svg.dvn-site-header-lang-icon:hover {
  opacity: 0.75;
}
</style>

<style scoped>
div.v-list-item-title {
  font-size: 0.8em;
  font-weight: 600;
}

/* removes the outline on the clicked v-icon */
/*
.v-icon:focus {
  outline: none !important;
  box-shadow: none !important;
}
*/
</style>
