<script setup lang="ts">
const pageSeoMeta = usePageSeoMeta()
pageSeoMeta.setLocalizedHeadAttributes()
pageSeoMeta.setLocalizedSeoMeta()

useAppContext().context = 'site'

useHead({
  meta: [
    {
      name: 'robots',
      content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1',
    },
  ],
})
</script>

<template>
  <VCard>
    <LayoutsPublicHeader />
    <DialogSnackbar />
    <VMain class="dvn-site-wrapper">
      <NuxtPage />
      <LayoutsPublicFooter />
    </VMain>
  </VCard>
</template>

<style lang="css">
@import url('@styles/site/index.css');
</style>
